// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import variables from './buildVariables.json';

export const environment = {
  production: variables.production,
  baseUrl: variables.baseUrl,
  googleMapsAPIKey: variables.googleMapsAPIKey,
  apiPrefix: variables.apiPrefix,
  clientSecret: variables.clientSecret,
  buildLabel: variables.buildNumber,
  activityTimerHubUrl: variables.activityTimerHubUrl,
  telephonyAutoReconnect: false,
  baseUrlForRaisingDefect: '',
  apiPrefixForRaisingDefect: '',
  projectKeyForRaisingDefect: '',
  tinyMceAPIKey: variables.tinyMceAPIKey,
  name: variables.name,
};

